import { AuthedUserSession } from "@/lib/session";
import {
  artists,
  rbacs,
  user_artist_mapping,
  user_venue_mapping,
  venues,
} from "@prisma/client";
import { isBoolean } from "is-what";
import { keys, objectify } from "radash";

interface CheckFnParams {
  session: AuthedUserSession;
  artist?: artists;
  venue?: venues;
  user_artist_mapping?: user_artist_mapping;
  user_venue_mapping?: user_venue_mapping;
}
type CheckFn = (params: CheckFnParams) => boolean | undefined | void;
export class RBAC {
  constructor(
    public params: CheckFnParams,
    public checks: CheckFn[]
  ) {}
  can() {
    for (const check of this.checks) {
      const result = check(this.params);
      if (isBoolean(result)) {
        return result;
      }
    }
  }
}

export const PERMISSIONS = {
  EDIT_ARTIST_PROFILE: class EDIT_ARTIST_PROFILE extends RBAC {},
  EDIT_EVENT_CONTACTS: class EDIT_EVENT_CONTACTS extends RBAC {},
  EDIT_EVENT_COUNTS: class EDIT_EVENT_COUNTS extends RBAC {},
  ADD_SHOWS: class ADD_SHOWS extends RBAC {},
  VENUE_REQUEST_CHANGES: class VENUE_REQUEST_CHANGES extends RBAC {},
  VIEW_AGENCY_DASHBOARD: class VIEW_AGENCY_DASHBOARD extends RBAC {},
  MODIFY_VENUE_CONTACTS: class MODIFY_VENUE_CONTACTS extends RBAC {},
};

export const CHECKS = {
  IS_ADMIN: ({ session }: CheckFnParams) => {
    return session.userData.user_category == "ADMIN";
  },
  IS_AGENT: ({ session }: CheckFnParams) => {
    return (
      session.userData.user_category == "ADMIN" ||
      session.userData.user_category == "AGENT"
    );
  },
  IS_PROMOTER: ({ session }: CheckFnParams) => {
    return (
      session.userData.user_category == "ADMIN" ||
      session.userData.user_category == "PROMOTER"
    );
  },
  IS_AGENT_OR_PROMOTER: ({ session }: CheckFnParams) => {
    return (
      session.userData.user_category == "ADMIN" ||
      session.userData.user_category == "AGENT" ||
      session.userData.user_category == "PROMOTER"
    );
  },
  IS_ENTITY_MEMBER: ({ session }: CheckFnParams) => {
    return !!session.userData.entities.at(0)?.id;
  },
  IS_ARTIST_CLAIMED_BY_USER: ({
    session,
    artist,
    user_artist_mapping,
  }: CheckFnParams) => {
    if (
      artist?.id == user_artist_mapping?.artist_id &&
      session.userData.id == user_artist_mapping?.user_id
    ) {
      return true;
    }
  },
  IS_VENUE_CLAIMED_BY_USER: ({
    session,
    venue,
    user_venue_mapping,
  }: CheckFnParams) => {
    if (venue?.venue_claimed == true) {
      if (
        venue?.id == user_venue_mapping?.venue_id &&
        session.userData.id == user_venue_mapping?.user_id
      ) {
        return true;
      }
      return false;
    }
  },
};

export interface Rbac extends rbacs {
  permission: keyof typeof PERMISSIONS;
  checks: (keyof typeof CHECKS)[];
}
export function buildRbacPermissions(params: CheckFnParams, dbrbacs: Rbac[]) {
  const rbacs = keys(PERMISSIONS).map((v) => {
    return (
      dbrbacs.find((vv) => vv.permission == v) ??
      ({ permission: v, checks: [] } as Rbac)
    );
  });
  return objectify(
    rbacs.map((rbac) => {
      return [
        rbac.permission,
        new PERMISSIONS[rbac.permission](params, [
          ...rbac.checks.map((check) => CHECKS[check]),
          () => false,
        ]),
      ] as const;
    }),
    (k) => k[0],
    (v) => v[1]
  );
}
