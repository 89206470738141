"use client";

import theme from "@/lib/theme";
import {
  Button,
  PillsInput,
  Switch,
  Tooltip,
  createTheme,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";

export default createTheme({
  ...theme,
  components: {
    DatePickerInput: DatePickerInput.extend({
      styles: {
        label: {
          fontWeight: 600,
        },
      },
    }),
    Switch: Switch.extend({
      styles: {
        label: {
          fontWeight: 600,
        },
      },
    }),
    // padding so that equal height to textinput fields
    PillsInput: PillsInput.extend({
      styles: {
        label: {
          fontWeight: 600,
        },
      },
    }),

    Button: Button.extend({
      styles: {
        inner: { fontSize: 14 },
      },
    }),
    CardSection: {
      defaultProps: {
        withBorder: true,
        inheritPadding: true,
        py: "sm",
      },
    },
    Checkbox: {
      defaultProps: {
        color: "blue.4",
      },
    },
    Combobox: {
      defaultProps: {
        transitionProps: {
          duration: 150,
          transition: "fade-down",
        },
      },
    },
    Popover: {
      defaultProps: {
        transitionProps: {
          duration: 150,
          transition: "fade-down",
        },
      },
    },
    PopoverDropdown: {
      defaultProps: {
        p: 0,
      },
    },
    Menu: {
      defaultProps: {
        transitionProps: {
          duration: 150,
          transition: "fade-down",
        },
      },
    },
    Modal: {
      defaultProps: {
        centered: true,
        size: "auto",
        padding: 0,
        trapFocus: true,
        withCloseButton: false,
      },
    },
    Tooltip: Tooltip.extend({
      defaultProps: {
        withArrow: true,
        arrowSize: 8,
        transitionProps: {
          duration: 150,
          transition: "fade",
        },
      },
    }),
  },
});
