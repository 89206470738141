"use client";

import { disableAppShell } from "@/components/navigation/NavigationRoutes";
import { useSession } from "@/lib/client/SessionProvider";
import { APP_SHELL_HEADER_HEIGHT, APP_SHELL_NAVBAR_WIDTH } from "@/lib/theme";
import {
  AppShell,
  AppShellHeader,
  AppShellMain,
  AppShellNavbar,
  Box,
  Burger,
  Button,
  Flex,
  Group,
  Image,
  Menu,
  MenuDropdown,
  MenuItem,
  MenuTarget,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  BellIcon,
  LogInIcon,
  LogOutIcon,
  SettingsIcon,
  UserIcon,
} from "lucide-react";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { Navbar } from "./Navbar";

export default function MainAppShell({
  children,
}: {
  children: React.ReactNode;
}) {
  const router = useRouter();
  const [opened, { toggle }] = useDisclosure();
  const session = useSession();
  const pathname = usePathname();
  if (disableAppShell(pathname)) {
    return <Box>{children}</Box>;
  }
  return (
    <AppShell
      navbar={{
        width: APP_SHELL_NAVBAR_WIDTH,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      header={{ height: APP_SHELL_HEADER_HEIGHT }}
    >
      <AppShellHeader bg="white">
        <Flex
          className="h-full"
          direction="row"
          align="center"
          justify="space-between"
          px={20}
        >
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <UnstyledButton component={Link} href="/dashboard">
            <Image src="/assets/icons/rc-logo-condensed.png" w={93} h={44} />
          </UnstyledButton>
          <Group>
            <Button variant="default" px="sm" className="border-0">
              <BellIcon className="h-6 w-6" />
            </Button>
            <Menu>
              <MenuTarget>
                <Button variant="default" px="sm" className="border-0">
                  <UserIcon className="h-6 w-6" />
                </Button>
              </MenuTarget>
              <MenuDropdown w={150}>
                <MenuItem
                  onClick={() => {
                    router.push("/settings/account");
                  }}
                >
                  <Group>
                    <SettingsIcon />
                    Settings
                  </Group>
                </MenuItem>
                {session.user ? (
                  <form action="/auth/signout" method="post">
                    <MenuItem type="submit">
                      <Group>
                        <LogOutIcon />
                        Logout
                      </Group>
                    </MenuItem>
                  </form>
                ) : (
                  <MenuItem
                    onClick={() => {
                      router.push("/login");
                    }}
                  >
                    <Group>
                      <LogInIcon />
                      Login
                    </Group>
                  </MenuItem>
                )}
              </MenuDropdown>
            </Menu>
          </Group>
        </Flex>
      </AppShellHeader>
      <AppShellNavbar pt={64} pb={32}>
        <Navbar onClose={() => toggle()} />
      </AppShellNavbar>
      <AppShellMain>
        <Box className="sm:p-4">{children}</Box>
      </AppShellMain>
    </AppShell>
  );
}
