import {
  BuildingIcon,
  CogIcon,
  PieChartIcon,
  PlusIcon,
  TrendingUpIcon,
  UserIcon,
} from "lucide-react";

export const NavItems = [
  { name: "Dashboard", href: "/dashboard", icon: TrendingUpIcon },
  { name: "Artists", href: "/artists", icon: UserIcon },
  { name: "Add Shows", href: "/addShows", icon: PlusIcon },
  { name: "Venues", href: "/venues", icon: BuildingIcon },
  { name: "Analysis", href: "/analysis", icon: PieChartIcon },
  { name: "Settings", href: "/settings/account", icon: CogIcon },
];
