"use client";

import { isBrowser } from "@supabase/ssr";
import posthog from "posthog-js";
import { PostHogProvider as $PostHogProvider } from "posthog-js/react";

if (
  isBrowser() &&
  process.env.NEXT_PUBLIC_POSTHOG_KEY &&
  process.env.NODE_ENV != "development"
) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
    capture_pageview: false,
    loaded: (posthog) => {
      if (process.env.NODE_ENV == "development") {
        posthog.opt_out_capturing();
      }
    },
  });
}

export default function PostHogProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return <$PostHogProvider client={posthog}>{children}</$PostHogProvider>;
}
