"use client";

import { buildRbacPermissions, Rbac } from "@/lib/rbac";
import { AuthedUserSession, UserSession } from "@/lib/session";
import { createContext, useContext } from "react";

export const RbacContext = createContext(
  {} as ReturnType<typeof buildRbacPermissions>
);
export const useRbac = () => useContext(RbacContext);

export default function RbacProvider({
  children,
  session,
  rbacs,
}: {
  children: React.ReactNode;
  session: UserSession;
  rbacs: Rbac[];
}) {
  const permissions = buildRbacPermissions(
    { session: session as AuthedUserSession },
    rbacs
  );
  return (
    <RbacContext.Provider value={permissions}>{children}</RbacContext.Provider>
  );
}
