"use client";
import { trpc } from "@/lib/trpc/query/client";
import type { ObjectValues } from "@/lib/utils";
import { formatDate } from "@/lib/utils/dates";
import {
  Box,
  Button,
  Group,
  Loader,
  Modal,
  SegmentedControl,
  Select,
  Table,
  TableTbody,
  TableTd,
  TableTh,
  TableThead,
  TableTr,
  Text,
  Textarea,
  Tooltip,
  useMatches,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { HelpCircleIcon } from "lucide-react";
import { useState } from "react";

export const REQUEST_TYPES = {
  BUG: "BUG",
  FEATURE: "FEATURE",
} as const;

export type RequestType = ObjectValues<typeof REQUEST_TYPES>;

export const SupportRequest = () => {
  const utils = trpc.useUtils();
  const [open, setOpen] = useState(false);
  const [supportText, setSupportText] = useState("");
  const [showExisting, setShowExisting] = useState(false);
  const [requestType, setRequestType] = useState<RequestType>(
    REQUEST_TYPES.BUG
  );

  const { data, isLoading: requestsLoading } =
    trpc.users.getSupportRequests.useQuery(undefined, {
      enabled: showExisting,
    });

  const supportRequestMutation = trpc.users.postSupportRequest.useMutation({
    onSuccess: () => {
      notifications.show({
        title: "Support Request Submitted",
        message:
          "Your support request has been submitted. We will get back to you shortly!",
        color: "green",
      });
      void utils.users.getSupportRequests.invalidate();
    },
    onError: (error) => {
      notifications.show({
        title: "Error",
        message: error.message,
        color: "red",
      });
    },
  });

  const disabled = useMatches({ base: true, sm: false });
  const label = useMatches({ base: "Help", sm: "" });

  return (
    <>
      <Modal opened={open} onClose={() => setOpen(false)} size="md">
        <Box className="relative h-full space-y-4 p-4 md:h-auto">
          <Box>
            <Text className="text-xl font-bold">Something wrong?</Text>
            <Text c="dimmed" className="text-sm">
              Use the entry form below to report any bugs, issues, or feature
              requests. You can also view existing requests to see their current
              status.
            </Text>
          </Box>
          <Box className="flex justify-center">
            <SegmentedControl
              value={showExisting ? "PREVIOUS_REQUESTS" : "NEW_REQUEST"}
              data={[
                { label: "New Request", value: "NEW_REQUEST" },
                { label: "Your Requests", value: "PREVIOUS_REQUESTS" },
              ]}
              onChange={(value) => {
                setShowExisting(value === "PREVIOUS_REQUESTS");
              }}
            />
          </Box>
          {!showExisting ? (
            <Box>
              <Box>
                <Select
                  data={[
                    {
                      label: "Feature Request",
                      value: REQUEST_TYPES.FEATURE,
                    },
                    { label: "Bug Fix", value: REQUEST_TYPES.BUG },
                  ]}
                  value={requestType}
                  label="Request Type"
                  onChange={(val) => setRequestType(val as RequestType)}
                ></Select>
              </Box>
              <Textarea
                className="mt-4 min-h-[100px]"
                value={supportText}
                onChange={(event) => {
                  if (event.target.validity.valid) {
                    setSupportText(event.target.value);
                  }
                }}
              />
              <Group justify="center">
                <Button
                  disabled={supportText == ""}
                  onClick={() =>
                    supportRequestMutation.mutate({
                      description: supportText,
                      request_type: requestType,
                    })
                  }
                  loading={supportRequestMutation.isPending}
                >
                  Submit
                </Button>
              </Group>
            </Box>
          ) : (
            <Box pb="lg">
              <Table>
                <TableThead className="font-semibold">
                  <TableTr>
                    <TableTh>Request</TableTh>
                    <TableTh>Type</TableTh>
                    <TableTh>Status</TableTh>
                    <TableTh>Created</TableTh>
                  </TableTr>
                </TableThead>
                <TableTbody className="">
                  {data?.map((supportRequest) => (
                    <TableTr key={supportRequest.id}>
                      <TableTd>{supportRequest.description}</TableTd>
                      <TableTd>{supportRequest.request_type}</TableTd>
                      <TableTd>{supportRequest.audit_status}</TableTd>
                      <TableTd>
                        {formatDate({ date: supportRequest.created_at })}
                      </TableTd>
                    </TableTr>
                  ))}
                  {requestsLoading && (
                    <Group justify="center" py="md">
                      <Loader type="bars" />
                    </Group>
                  )}
                  {data?.length == 0 && (
                    <TableTr>
                      <TableTd colSpan={3} className="text-center" py="md">
                        No requests found
                      </TableTd>
                    </TableTr>
                  )}
                </TableTbody>
              </Table>
            </Box>
          )}
        </Box>
      </Modal>
      <Tooltip disabled={disabled} label="Help" position="right">
        <Button
          size={"lg"}
          p={{ sm: 12 }}
          variant="subtle"
          aria-label="Help"
          className="text-gray-4"
          leftSection={<HelpCircleIcon width={24} height={24} />}
          {...(!disabled && {
            classNames: { section: "m-0" },
          })}
          onClick={() => setOpen(true)}
        >
          {label}
        </Button>
      </Tooltip>
    </>
  );
};
