"use client";

import type { AuthedUserSession, UserSession } from "@/lib/session";
import { createContext, useContext } from "react";

export const SessionContext = createContext({} as AuthedUserSession);
export const useSession = () => useContext(SessionContext);

export default function SessionProvider({
  children,
  session,
}: {
  children: React.ReactNode;
  session: UserSession;
}) {
  return (
    <SessionContext.Provider value={session as AuthedUserSession}>
      {children}
    </SessionContext.Provider>
  );
}
