import { SupportRequest } from "@/components/support/SupportRequest";
import { useRbac } from "@/lib/client/RbacProvider";
import { useSession } from "@/lib/client/SessionProvider";
import { cn } from "@/lib/utils";
import {
  AppShellSection,
  Button,
  Stack,
  Tooltip,
  useMatches,
} from "@mantine/core";
import { motion } from "framer-motion";
import {
  LayoutDashboardIcon,
  LogOutIcon,
  LucideIcon,
  ShieldEllipsisIcon,
} from "lucide-react";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { NavItems } from "./NavItems";

const NavbarLink = ({
  name,
  href,
  active,
  icon: Icon,
  onClose,
}: {
  name: string;
  href: string;
  active?: boolean;
  icon: LucideIcon;
  onClose: () => void;
}) => {
  const disabled = useMatches({ base: true, sm: false });
  const label = useMatches({ base: name, sm: "" });
  return (
    <Tooltip disabled={disabled} label={name} position="right">
      <Button
        size={"lg"}
        p={{ sm: 12 }}
        // variant={active ? "light" : "subtle"}
        variant={"transparent"}
        aria-label={name}
        className={cn(
          active ? "text-blue-4" : "text-gray-4",
          "z-100 relative transition"
        )}
        component={Link}
        href={href}
        leftSection={<Icon width={24} height={24} />}
        {...(!disabled && {
          classNames: { section: "m-0" },
        })}
        onClick={onClose}
      >
        {active && (
          <motion.span
            layoutId="bubble"
            className="absolute inset-0 z-0 bg-blue-5/10"
            style={{ borderRadius: 8 }}
            transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
          />
        )}
        {label}
      </Button>
    </Tooltip>
  );
};
export const Navbar = ({ onClose }: { onClose: () => void }) => {
  const rbac = useRbac();
  const router = useRouter();
  const disabled = useMatches({ base: true, sm: false });
  const label = useMatches({ base: "Log Out", sm: "" });
  const pathname = usePathname();
  const session = useSession();
  const links = [
    ...(rbac.VIEW_AGENCY_DASHBOARD.can()
      ? [
          {
            name: "Overview",
            href: "/overview",
            icon: LayoutDashboardIcon,
          },
        ]
      : []),
    ...NavItems.filter((v) => {
      if (v.name == "Add Shows" && !rbac.ADD_SHOWS.can()) {
        return false;
      }
      return true;
    }),
    ...(session.userData.isAdmin
      ? [
          {
            name: "Admin",
            href: "/admin",
            icon: ShieldEllipsisIcon,
          },
        ]
      : []),
  ].map((link) => {
    return (
      <NavbarLink
        onClose={onClose}
        key={link.name}
        {...link}
        active={pathname.split("/")[1]!.startsWith(link.href.split("/")[1]!)}
      />
    );
  });
  return (
    <>
      <AppShellSection grow>
        <Stack gap={8} align="center">
          {links}
        </Stack>
      </AppShellSection>
      <AppShellSection>
        <Stack gap={8} align="center">
          <SupportRequest />
          <form action="/auth/signout" method="post">
            <Tooltip disabled={disabled} label="Log Out" position="right">
              <Button
                size={"lg"}
                p={{ sm: 12 }}
                variant="subtle"
                aria-label="Log Out"
                className="text-gray-4"
                leftSection={<LogOutIcon width={24} height={24} />}
                {...(!disabled && {
                  classNames: { section: "m-0" },
                })}
                type="submit"
              >
                {label}
              </Button>
            </Tooltip>
          </form>
        </Stack>
      </AppShellSection>
    </>
  );
};
