import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export type ObjectValues<T> = T[keyof T];

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// TODO - think of general url best practices. Intentionally left open for now
export function isValidUrl(url: string | undefined | null) {
  return (
    // typeof url === "string" && url.trim().length > 0 && /^https?:\/\//.test(url)
    typeof url === "string" && url.trim().length > 0
  );
}

export function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined;
}

export function abbreviate(n: number) {
  n = Number(n || 0);
  if (n < 1e3) return n.toFixed(2).replace(/[.0]+$/, "");
  if (n >= 1e3 && n < 1e6) return String(+(n / 1e3).toFixed(1)) + "K";
  if (n >= 1e6 && n < 1e9) return String(+(n / 1e6).toFixed(1)) + "M";
  if (n >= 1e9 && n < 1e12) return String(+(n / 1e9).toFixed(1)) + "B";
  if (n >= 1e12) return String(+(n / 1e12).toFixed(1)) + "T";
}
