"use client";

import { ModalsProvider } from "@mantine/modals";
import ModalContainer from "react-modal-promise";

export default function ModalProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ModalsProvider>
      {children}
      <ModalContainer />
    </ModalsProvider>
  );
}
