import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/navigation/MainAppShell.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/lib/client/ModalProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/lib/client/PostHogPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/lib/client/PostHogProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/lib/client/RbacProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/lib/client/SessionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/lib/mantine.ts");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/vercel/path0/lib/trpc/query/client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/carousel/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Box"] */ "/vercel/path0/node_modules/@mantine/core/esm/core/Box/Box.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/vercel/path0/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/dates/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/dropzone/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Notifications"] */ "/vercel/path0/node_modules/@mantine/notifications/esm/Notifications.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["cleanNotifications","cleanNotificationsQueue","createNotificationsStore","hideNotification","notifications","notificationsStore","showNotification","updateNotification","updateNotificationsState","useNotifications"] */ "/vercel/path0/node_modules/@mantine/notifications/esm/notifications.store.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/notifications/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/mantine-react-table/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-open-sans\"}],\"variableName\":\"font\"}");
