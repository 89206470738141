import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import isYesterday from "dayjs/plugin/isYesterday";
import minMax from "dayjs/plugin/minMax";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { isDate, isString } from "radash";
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);
dayjs.extend(minMax);
dayjs.extend(relativeTime);

export const DEFAULT_TIMEZONE = "America/New_York" as const;

export const WeekDaysCondensed = [
  "Su",
  "Mo",
  "Tu",
  "We",
  "Th",
  "Fr",
  "Sa",
] as const;
export const WeekDays = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
] as const;
export enum ScheduledDays {
  SUNDAY = "SUNDAY",
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
}

export const formatDate = ({
  date,
  format = "MM/DD/YY",
  tz,
}: {
  date: Date;
  format?: string;
  tz?: string;
}) => {
  if (tz) {
    return dayjs(date).tz(tz).format(format);
  }
  return dayjs(date).format(format);
};

export const buildTzDate = (
  date: Date,
  time: string | undefined,
  currentTz: string | null | undefined,
  targettz: string | null | undefined
) => {
  const dateString =
    formatDate({
      date: date,
      tz: currentTz ?? undefined,
      format: "MMM DD, YYYY",
    }) +
    " " +
    (time ?? "20:00");
  return dayjs
    .tz(dateString, "MMM DD, YYYY HH:mm", targettz ?? DEFAULT_TIMEZONE)
    .toDate();
};

export function parseCron(date: string, condensed = false) {
  if (!date) return "";
  const days = date.split(" ").pop()?.split(",").map(Number);

  const dayList = days?.map((day) =>
    condensed ? WeekDaysCondensed[day] : WeekDays[day]
  );
  return condensed ? dayList?.join("/") : dayList?.join(", ");
}

export function cronToScheduled(date: string) {
  if (!date) return [];
  const days = date.split(" ").pop()?.split(",").map(Number);
  const dayList: ScheduledDays[] = [];
  if (days) {
    days?.forEach((day) => {
      const dayItem = Object.values(ScheduledDays)[day];
      if (dayItem) dayList.push(dayItem);
    });
  }

  return dayList;
}

export function scheduledToCron(days: ScheduledDays[]) {
  return (
    "0 10 * * " +
    days
      .map((day) => WeekDays.indexOf(day))
      .sort()
      .join(",")
  );
}

/** returns the `2024-02-06` before the `T` */
export function toISODateString(date: Date) {
  return date.toISOString().split("T")[0]!;
}

export function getMinMaxDates(dates: (Date | string | null | undefined)[]) {
  const days = dates
    .map((v) => (isString(v) ? new Date(v) : v))
    .filter(isDate)
    .map((v) => dayjs(v));
  return {
    min: dayjs.min(days)?.toDate(),
    max: dayjs.max(days)?.toDate(),
  };
}

export function fromNow(date: Date | null | undefined) {
  let fromNow = dayjs(date).fromNow();
  if (dayjs(date).isToday()) {
    fromNow = "Today";
  } else if (dayjs(date).isTomorrow()) {
    fromNow = "Tomorrow";
  } else if (dayjs(date).isYesterday()) {
    fromNow = "Yesterday";
  }
  return fromNow;
}

export function differenceInDays(d1: Date, d2: Date) {
  return dayjs(d1).diff(dayjs(d2), "day");
}

export function getWeekRange(
  range: { start: Dayjs; end: Dayjs },
  separator = "-"
) {
  return `${range.start.format("MMM D")} ${separator} ${range.end.format("MMM D YYYY")}`;
}

export function toTzDayjs(
  input: dayjs.ConfigType,
  timezone_id?: string | null
) {
  return timezone_id ? dayjs(input).tz(timezone_id) : dayjs(input);
}
