import { MantineThemeOverride } from "@mantine/core";

export const APP_SHELL_HEADER_HEIGHT = 64;
export const APP_SHELL_NAVBAR_WIDTH = 80;

export default {
  fontFamily: "var(--font-open-sans)",
  colors: {
    blue: [
      "#B3C8E7",
      "#A1B9E3",
      "#8FAAD9",
      "#8AB9FF",
      "#4F81EE", // primary
      "#3E6AC5",
      "#2C5BBB",
      "#1A4CAC",
      "#093D9D",
      "#002E8B",
    ],
    orange: [
      "#FFEFE4",
      "#FFDDCD",
      "#FFB99B",
      "#FF8957", // secondary
      "#FE7337",
      "#FE5E1A",
      "#FF5209",
      "#E44300",
      "#CB3A00",
      "#B12E00",
    ],
    gray: [
      "#f9fafb",
      "#f3f4f6",
      "#e5e7eb",
      "#d1d5db",
      "#9ca3af", // default border color
      "#6b7280",
      "#4b5563",
      "#374151",
      "#1f2937",
      "#111827",
    ],
  },

  lineHeights: {
    xs: "1",
    sm: "1.25",
    md: "1.5",
    lg: "1.75",
    xl: "1.75",
    "2xl": "2",
    "3xl": "2.25",
    "4xl": "2.5",
    "5xl": "1",
    "6xl": "1",
    "7xl": "1",
    "8xl": "1",
    "9xl": "1",
  },
  fontSizes: {
    xs: "0.75",
    sm: "0.875",
    md: "1",
    lg: "1.125",
    xl: "1.25",
    "2xl": "1.5",
    "3xl": "1.875",
    "4xl": "2.25",
    "5xl": "3",
    "6xl": "3.75",
    "7xl": "4.5",
    "8xl": "6",
    "9xl": "8",
  },
  defaultRadius: "md",
  primaryColor: "blue",
  primaryShade: 4,
  cursorType: "pointer",
} as MantineThemeOverride;
