"use client";

import { getBaseUrl } from "@/lib/urls";
import type { AppRouter } from "@/server/appRouter";
import { isBrowser } from "@supabase/ssr";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  createTRPCClient,
  httpBatchLink,
  loggerLink,
  unstable_httpBatchStreamLink,
} from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import { useState } from "react";
import superjson from "superjson";

export const invoker = createTRPCClient<AppRouter>({
  links: [
    httpBatchLink({
      transformer: superjson,
      // methodOverride: "POST",
      url: `${getBaseUrl()}/api/trpc`,
      headers: { "x-trpc-source": "query-invoker" },
    }),
  ],
});

export const trpc = createTRPCReact<AppRouter>();

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        refetchOnWindowFocus: process.env.NODE_ENV != "development",
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;
function getQueryClient() {
  if (isBrowser()) {
    if (!browserQueryClient) {
      browserQueryClient = makeQueryClient();
    }
    return browserQueryClient;
  } else {
    return makeQueryClient();
  }
}

export function TRPCReactProvider({
  children,
  cookie,
}: {
  children: React.ReactNode;
  cookie: string;
}) {
  const queryClient = getQueryClient();

  const [trpcClient] = useState(() => {
    return trpc.createClient({
      links: [
        loggerLink({
          colorMode: "ansi",
          enabled: (op) => {
            return (
              process.env.NODE_ENV == "development" ||
              (op.direction == "down" && op.result instanceof Error)
            );
          },
        }),
        // httpBatchLink({
        unstable_httpBatchStreamLink({
          transformer: superjson,
          // methodOverride: "POST",
          url: `${getBaseUrl()}/api/trpc`,
          headers: { cookie, "x-trpc-source": "query-client" },
        }),
      ],
    });
  });

  return (
    <QueryClientProvider client={queryClient}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        {/* <ReactQueryStreamedHydration
          queryClient={queryClient}
          transformer={superjson}
        > */}
        {children}
        {/* </ReactQueryStreamedHydration> */}
      </trpc.Provider>
    </QueryClientProvider>
  );
}
