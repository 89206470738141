import { match, parse } from "matchit";

export function authNecessary(pathname: string) {
  return (
    match(
      pathname,
      [
        "/admin",
        "/admin/:pid",
        "/admin/parser",
        "/admin/prompt",
        "/admin/inbound",
        "/admin/retry-log",
        "/admin/api",
        "/analysis",
        "/analysis/comparison",
        "/artists",
        "/artists/:pid",
        "/dashboard",
        "/overview",
        "/settings",
        "/settings/:tab",
        "/update-password",
        "/venues",
        "/venues/:id",
        "/addShows",
      ].map(parse)
    ).length > 0
  );
}

export function disableAppShell(pathname: string) {
  return (
    match(
      pathname,
      [
        "/",
        "/login",
        "/alert",
        "/update-password",
        "/venue-claim",
        "/api-doc",
        //
      ].map(parse)
    ).length > 0
  );
}
